import React from 'react'
import Alert from 'react-bootstrap/Alert';
import {get} from '../../utils/request'
import Table from "react-bootstrap/Table";
import Layout from "../../component/Layout";
import StorageNav from "../../component/StorageNav";
import filesize from "filesize";
import {ProgressBar, Spinner} from "react-bootstrap";
import LocationSelector from "../../component/LocationSelector";
import fileSize from "filesize";

function humanReadableCount(count) {
    const byteSize = fileSize(count)
    return byteSize.replace(/B+$/, '');
}

class StorageOverview extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const location = query.get('location');
        const extraStatistics = query.get('extraStatistics') ? "1" : "";

        this.state = {
            loaded: false,
            location: location,
            extraStatistics: extraStatistics,
            data: {},
            errorMessage: "",
        };
        this.loadData = this.loadData.bind(this);
    }
    async loadData() {
        if(!this.state.location) {
            return;
        }

        const [res, err] = await get(this, "/storage/overview/in/" + this.state.location + "?extraStatistics=" + this.state.extraStatistics);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }

        this.setState({data: res.data});
        this.setState({errorMessage: ""});
        this.setState({loaded: true});
    }

    async componentDidMount() {
        await this.loadData();
    }
    render() {
        if (!this.state.loaded) {
            return <Layout activeMenu="storage">
                <StorageNav activeKey="overview"/>
                <h1>Storage statistics</h1>
                <LocationSelector />
                {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
                <Spinner animation="border" variant="primary"/> Data not loaded...
                <br/><br/>
            </Layout>;
        }

        const extraStatistics = this.state.extraStatistics;

        const statistics = this.state.data.Statistics;
        const nodes = this.state.data.Nodes;
        const containers = this.state.data.Containers;

        const storage = statistics.Storage;
        const storageUsagePercent = Math.round(storage.Usage / storage.Size * 100);
        const storageDeletedBlocksPercent = Math.round(storage.ExtraDeletedBlocks / storage.Size * 100);
        const storageDeletedInBlocksPercent = Math.round(storage.ExtraDeletedInBlocks / storage.Size * 100);
        const storageDeletedInBlocksDelayedPercent = Math.round(storage.ExtraDeletedInBlocksDelayed / storage.Size * 100);
        const storageDeletedObjectsPercent = Math.round(storage.ExtraDeletedObjects / storage.Size * 100);

        const user = statistics.User;
        const userUsagePercent = Math.round(user.Usage / storage.Size * 100);
        const userPaidPercent = Math.round(user.Paid / storage.Size * 100);
        const userPaidEuros = Math.round((statistics.User.Paid / (1024 * 1024 * 1024 * 1024)) * 6);

        const containerStatistics = statistics.Container;
        const containersWriteActivePercent = Math.round(containerStatistics.WriteActive / containers.length * 100);
        const containersKeepEmptyPercent = Math.round(containerStatistics.KeepEmpty / containers.length * 100);

        const containerProvider = statistics.ContainerProvider;

        const driveStatistics = statistics.Drive;
        const driveOnlinePercent = Math.round(driveStatistics.Online / driveStatistics.Total * 100);
        const driveDataPercent = Math.round(driveStatistics.Data / driveStatistics.Total * 100);

        return <Layout activeMenu="storage">
            <StorageNav activeKey="overview"/>
            <h1>Storage statistics</h1>
            <LocationSelector />
            {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
            <table width="100%">
                <tbody>
                <tr>
                    <td width="50%" valign="top">
                        <Table bordered hover>
                            <tbody>
                            <tr>
                                <td width="33%">
                                    <b>Storage</b>
                                    &nbsp;<a href={"/storage?location=" + this.state.location + "&extraStatistics=1"}>Extra statistics</a>
                                </td>
                                <td width="33%">{filesize(storage.Size)}</td>
                                <td width="33%"></td>
                            </tr>
                            <tr>
                                <td>Container usage </td>
                                <td>{filesize(storage.Usage)} ({storageUsagePercent}%)</td>
                                <td><ProgressBar now={storageUsagePercent} max={100}/></td>
                            </tr>
                            {extraStatistics && <>
                                <tr>
                                    <td>Deleted blocks</td>
                                    <td>{filesize(storage.ExtraDeletedBlocks)} ({storageDeletedBlocksPercent}%, count: {humanReadableCount(storage.ExtraDeletedBlocksCount)})</td>
                                    <td><ProgressBar variant="warning" now={storageDeletedBlocksPercent} max={100}/></td>
                                </tr>
                                <tr>
                                    <td>Deleted in blocks</td>
                                    <td>{filesize(storage.ExtraDeletedInBlocks)} ({storageDeletedInBlocksPercent}%, count: {humanReadableCount(storage.ExtraDeletedInBlocksCount)})</td>
                                    <td><ProgressBar variant="warning" now={storageDeletedInBlocksPercent} max={100}/></td>
                                </tr>
                                <tr>
                                    <td>Deleted in blocks delayed</td>
                                    <td>{filesize(storage.ExtraDeletedInBlocksDelayed)} ({storageDeletedInBlocksDelayedPercent}%, count: {humanReadableCount(storage.ExtraDeletedInBlocksDelayedCount)}, delay: {storage.ExtraDeletedInBlocksDelay})</td>
                                    <td><ProgressBar variant="warning" now={storageDeletedInBlocksDelayedPercent} max={100}/></td>
                                </tr>
                                <tr>
                                    <td>Deleted objects</td>
                                    <td>{filesize(storage.ExtraDeletedObjects)} ({storageDeletedObjectsPercent}%, count: {humanReadableCount(storage.ExtraDeletedObjectsCount)})</td>
                                    <td><ProgressBar variant="warning" now={storageDeletedObjectsPercent} max={100}/></td>
                                </tr>
                            </>}
                            <tr>
                                <td>Users</td>
                                <td>{filesize(user.Usage)} ({userUsagePercent}%)</td>
                                <td><ProgressBar now={userUsagePercent} max={100}/></td>
                            </tr>
                            <tr>
                                <td>Users paid</td>
                                <td>{filesize(statistics.User.Paid)} ({userPaidPercent}%, {userPaidEuros} &euro;/month)</td>
                                <td><ProgressBar now={userPaidPercent} max={100}/></td>
                            </tr>
                            <tr>
                                <td><b>Drives</b></td>
                                <td>{driveStatistics.Total}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Online</td>
                                <td>{driveStatistics.Online} ({driveOnlinePercent}%)</td>
                                <td><ProgressBar now={driveOnlinePercent} max={100}/></td>
                            </tr>
                            <tr>
                                <td>Data</td>
                                <td>{driveStatistics.Data} ({driveDataPercent}%)</td>
                                <td><ProgressBar now={driveDataPercent} max={100}/></td>
                            </tr>
                            </tbody>
                        </Table>
                    </td>
                    <td width="50%" valign="top">
                        <Table bordered hover>
                            <tbody>
                            <tr>
                                <td width="33%"><b>Containers</b></td>
                                <td width="33%">{containers.length}</td>
                                <td width="33%">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>WriteActive</td>
                                <td>{containerStatistics.WriteActive} ({containersWriteActivePercent}%)</td>
                                <td><ProgressBar now={containersWriteActivePercent} max={100}/></td>
                            </tr>
                            <tr>
                                <td>KeepEmpty</td>
                                <td>{containerStatistics.KeepEmpty} ({containersKeepEmptyPercent}%)</td>
                                <td><ProgressBar variant={"warning"} now={containersKeepEmptyPercent} max={100}/></td>
                            </tr>
                            <tr>
                                <td><b>Container Provider</b></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Max block size</td>
                                <td>{filesize(containerProvider.MaxBlockSize)}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Max fragment size</td>
                                <td>{filesize(containerProvider.MaxFragmentSize)}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Priority Fill percent</td>
                                <td>{containerProvider.PriorityFillPercent}%</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Priority multiploer</td>
                                <td>{containerProvider.PriorityMultiplier}x</td>
                                <td></td>
                            </tr>
                            </tbody>
                        </Table>
                    </td>
                </tr>
                </tbody>
            </table>


            <h1>Storage overview</h1>
            <Table bordered hover>
                <thead className="thead-light">
                <tr>
                    <th>&nbsp;</th>
                    {nodes.map((node, index) => {
                        return <th key={node.NodeId}>
                            {node.Services.StorageDrive.Online &&
                            <span className="badge badge-pill badge-success">Drive</span>}
                            {!node.Services.StorageDrive.Online &&
                            <span className="badge badge-pill badge-warning">Drive</span>}

                            <a href={"/storage/node/" + node.NodeId + "/view?location=" + this.state.location}>{node.Uuid}</a>
                        </th>
                    })}
                </tr>
                </thead>
                <tbody>
                {containers
                    .sort((a, b) => a.ContainerId > b.ContainerId ? 1 : -1)
                    .map((container, index) => {
                        const containerFree = container.Size - container.Usage;
                        const containerUsedPercent = Math.round(container.Usage / container.Size * 100);
                    return <tr key={container.ContainerId}>
                        <td>
                            {container.ContainerId === "000000000000000000000000" &&
                            <div>Drives not added to container</div>
                            }
                            {container.ContainerId !== "000000000000000000000000" &&
                            <div>
                                <div>
                                    <a href={"/storage/container/" + container.ContainerId + "/view?location=" + this.state.location}>{container.ContainerId}</a>
                                </div>
                                <div>
                                    {container.WriteActive && <span className="badge badge-pill badge-success">WriteActive</span>}
                                    {!container.WriteActive && <span className="badge badge-pill badge-warning">NOT WriteActive</span>}

                                    {container.KeepEmpty && <span className="badge badge-pill badge-warning">KeepEmpty</span>}
                                    {container.PauseDeletes && <span className="badge badge-pill badge-warning">PauseDeletes</span>}
                                </div>
                                <div>Size {filesize(container.Size)}</div>
                                <div>Free {filesize(containerFree)}</div>
                                <div>Usage {filesize(container.Usage)} ({containerUsedPercent}%)</div>
                                <div><ProgressBar now={containerUsedPercent} max={100}/></div>
                            </div>
                            }
                        </td>
                        {container.DrivesByNodes && container.DrivesByNodes.map((nodeDrives, index) => {
                            return <td key={index}>
                                    {nodeDrives && nodeDrives.map((drive, index) => {
                                        return <div key={drive.DriveId}>
                                            {drive.Online && <span className="badge badge-pill badge-success">Online</span>}
                                            {!drive.Online && <span className="badge badge-pill badge-warning">Offline</span>}
                                            <a href={"/storage/drive/" + drive.DriveId + "/view?location=" + this.state.location}>{drive.Uuid}</a>
                                        </div>
                                    })}
                            </td>
                        })}
                    </tr>
                })}
                </tbody>
            </Table>
        </Layout>;
    }
}

export default StorageOverview